import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
  Button,
  TouchableWithoutFeedback,
  Platform,
  useWindowDimensions,
} from "react-native";
import { ListItem, SearchBar } from "react-native-elements";
import TagsView from "./tagsView";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import * as FileSystem from "expo-file-system";
import { RootTabScreenProps } from "../types";
import CardItem from "./CardItem";
import FlatlistHeader from "./FlatlistHeader";

const gifDir = FileSystem.documentDirectory + "codeListenData/";

export interface ICodelist {
  key: string;
  code: string;
  unternummerierung: string;
  beschreibung: string;
  bahnart: string;
  codegruppe: string;
  bemerkung: string;
  bilder?: Array<object>;
}
interface IStateSearchList {
  data: Array<ICodelist>;
  arrayholder: Array<ICodelist>;
  loadingData: boolean;
  error?: object;
  search?: string;

  selectedGruppe: Array<string>;
  selectedTags: Array<string>;
  codegruppen: Array<string> | undefined;
}

interface SearchListProps {
  codeliste: ICodelist[];
  navigation: RootTabScreenProps<"TabOne">;
}
const SearchList = (props: SearchListProps) => {
  const [state, setState] = useState<IStateSearchList>({
    loadingData: false,
    selectedGruppe: [],
    selectedTags: [],
    data: [],
    arrayholder: [],
    codegruppen: undefined,
  });
  const [searchInput, setsearchInput] = useState<string>("");

  useEffect(() => {
    var codeg = findElements(props.codeliste, "codegruppe");
    setState({
      ...state,
      data: props.codeliste,
      arrayholder: props.codeliste,
      codegruppen: codeg,
    });
  }, [props]);

  useEffect(() => {
    SearchFilterFunction("");
  }, [state.selectedTags]);
  const { width } = useWindowDimensions();

  const SearchFilterFunction = (text: string) => {
    setsearchInput(text);

    var textData: string;
    if (!state.arrayholder) return;
    var newData = state.arrayholder.filter((item) => {
      const itemData = `${item.beschreibung.toUpperCase()} ${item.bahnart.toUpperCase()} ${item.codegruppe.toUpperCase()} ${item.code.toUpperCase()}`;
      textData = text != null ? `${text.toUpperCase()}` : ""; //`${state.search.toUpperCase()}`;
      return itemData.indexOf(textData) > -1;
    });

    if (state.selectedTags && state.selectedTags.length > 0) {
      newData = newData.filter((item) => {
        const itemData = `${item.bahnart.toUpperCase()} ${item.codegruppe.toUpperCase()}`;
        for (var i = 0; i < state.selectedTags.length; i++) {
          if (itemData.includes(state.selectedTags[i].toUpperCase())) {
            return itemData.includes(state.selectedTags[i].toUpperCase());
          } else continue;
        }
      });
      setState({
        ...state,
        data: newData,
      });
      return;
    } else {
      textData = "";
    }

    newData = newData.filter((item) => {
      const itemData = `${item.bahnart.toUpperCase()} ${item.codegruppe.toUpperCase()}`;

      return itemData.indexOf(textData) > -1;
    });
    setState({
      ...state,
      data: newData,
    });
  };

  const findElements = (items: Array<ICodelist>, key: string) => {
    if (!items) return;
    var lookup = {};
    var result = [];

    for (var item, i = 0; (item = items[i++]); ) {
      var name = item[key];

      if (!(name in lookup)) {
        lookup[name] = 1;
        result.push(name);
      }
    }
    return result;
  };
  const resetFilter = () => {
    setState({
      ...state,
      selectedTags: [],
    });
  };
  const addOrRemove = (item: string, typ: string) => {
    var index = state.selectedTags.indexOf(item);
    if (index === -1) {
      setState({ ...state, selectedTags: [...state.selectedTags, item] });
    } else {
      setState({
        ...state,
        selectedTags: state.selectedTags.filter((i) => i != item),
      });
    }
    return;
  };

  if (state.loadingData) {
    return (
      <View style={{ alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator />
      </View>
    );
  }
  return (
    <View style={{ flex: 1 }}>
      <FlatList
        numColumns={1}
        data={state.data}
        renderItem={({ item }) => (
          <CardItem item={item} navigation={props.navigation} />
        )}
        keyExtractor={(item, index) => index.toString()}
        contentContainerStyle={{ padding: 10 }}
        ListHeaderComponent={
          <FlatlistHeader
            searchFilterFunction={SearchFilterFunction}
            resetFilter={resetFilter}
            searchInput={searchInput}
            codegruppen={state.codegruppen}
            addOrRemove={addOrRemove}
            selectedTags={state.selectedTags}
          />
        }
      />
    </View>
  );
};
export default SearchList;

const styles = StyleSheet.create({
  baseText: {
    fontFamily: "Cochin",
  },
  titleText: {
    fontSize: 15,
    fontWeight: "bold",
  },
  viewContent: { flex: 1, flexDirection: "row" },
});
