import React from 'react'
import { TouchableOpacity, View, Text, StyleSheet, Image } from 'react-native'


export default class BackgroundButton extends React.Component {
  render() {
    const styles = this.makeStyles()
    return (
      <TouchableOpacity style={styles.touchable} onPress={this.props.onPress}>
        <View style={styles.view}>
          <Text style={styles.text}>{this.props.title}</Text>
        </View>
      </TouchableOpacity>
    )
  }


makeStyles() {
    return StyleSheet.create({
      view: {
        flexDirection: 'row',
        borderRadius: 10,
        borderColor: this.props.borderColor,
        borderWidth: 1,
        backgroundColor: this.props.backgroundColor,
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 16,
        paddingRight: 16
      },
      touchable: {
        marginLeft: 4,
        marginRight: 4,
        marginBottom: 4
      },
      image: {
        marginRight: 8
      },
      text: {
        textAlign: 'center',
        color: this.props.textColor,
      }
    })
  }
}