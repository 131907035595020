import React, { useEffect, useState } from "react";
import { StyleSheet, Platform } from "react-native";

import { Text, View } from "../components/Themed";
import { RootTabScreenProps } from "../types";
import SearchList from "../components/searchableList";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/native";
import * as FileSystem from "expo-file-system";

export default function TabOneScreen({
  navigation,
}: RootTabScreenProps<"TabOne">) {
  const [selectedCodelist, setselectedCodelist] = useState({});
  const [selectedCodelistContent, setselectedCodelistContent] = useState([]);
  const isFocused = useIsFocused();
  AsyncStorage.getItem("selectedCodelist").then((value) => {
    if (JSON.parse(value)?.name) {
      navigation.setOptions({
        title: "Codeliste " + JSON.parse(value)["name"],
      });
    }
  });

  useEffect(() => {
    // Call only when screen open or when back on screen
    if (isFocused) {
      loadCodelist();
    }
  }, [isFocused]);

  const loadCodelist = () => {
    AsyncStorage.getItem("selectedCodelist").then((value) => {
      if (value) {
        setselectedCodelist(JSON.parse(value));

        //getCodelistContent => web online, os => offline

        AsyncStorage.getItem("serverUrl", (errs, serverUrl) => {
          if (!errs) {
            if (Platform.OS == "web") {
              fetch(serverUrl + "/" + JSON.parse(value)["filename"])
                .then((response) => response.json())
                .then((responseJson) => {
                  //console.log(responseJson);
                  setselectedCodelistContent(responseJson);
                })
                .catch((error) => {
                  console.error(error);
                });
            } else {
              const gifDir = FileSystem.documentDirectory + "codeListenData/";
              fetch(gifDir + JSON.parse(value)["key"] + ".json")
                .then((response) => response.json())
                .then((responseJson) => {
                  setselectedCodelistContent(responseJson);
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          }
        });
      } else setselectedCodelist("");
    });
  };
  return (
    <SearchList codeliste={selectedCodelistContent} navigation={navigation} />
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
