import React from "react";
import { View, StyleSheet, Button } from "react-native";
import BackgroundButton from "./backgroundButton";

export default class TagsView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <View style={styles.container}>{this.makeButtons()}</View>;
  }
  addOrRemove(array, item) {
    const exists = array.includes(item);

    if (exists) {
      //var result;
      const index = array.indexOf(item);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array; //result// array.filter((c) => { return c !== item })
    } else {
      const result = array;

      result.push(item);
      return result;
    }
  }

  onPress = (tag) => {
    //tagesViewOn = true

    if (this.props.isExclusive) {
      if (this.props.selectedTags.includes(tag)) this.props.selectedTags = [];
      else this.props.selectedTags = [tag];
    } else {
      //state.selected = addOrRemove(state.selected, tag)
      //this.props.selectedTags = this.addOrRemove(this.props.selectedTags, tag);
      //console.log('TagsView onPress \n',state.selected)
    }

    this.props.onSelectionChange(tag);
  };

  makeButtons() {
    if (this.props.all) {
      return this.props.all.map((tag, i) => {
        const on = this.props.selectedTags.includes(tag);
        const backgroundColor = on ? "#2f95dc" : "#cccccc";
        const textColor = "#000000";
        const borderColor = "#000000";

        return (
          <BackgroundButton
            backgroundColor={backgroundColor}
            textColor={textColor}
            borderColor={borderColor}
            onPress={() => {
              this.onPress(tag);
            }}
            key={i}
            showImage={on}
            title={tag}
          />
        );
      });
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 5,
    fontSize: 10,
  },
});
