import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { useEffect, useRef, useState, memo } from "react";
import {
  Image,
  Text,
  Button,
  StyleSheet,
  TouchableHighlight,
  View,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { Searchbar, useTheme } from "react-native-paper";
import TagsView from "./tagsView";

interface HeaderProps {
  searchFilterFunction: (text: string) => void;
  resetFilter: () => void;
  searchInput: string;
  codegruppen: any[] | undefined;
  addOrRemove: (item: string, typ: string) => void;
  selectedTags: string[];
}

const FlatlistHeader = (props: HeaderProps) => {
  const theme = useTheme();
  return (
    <View>
      <Searchbar
        placeholder="Suche..."
        onChangeText={props.searchFilterFunction}
        value={props.searchInput}
        onBlur={undefined}
        onFocus={undefined}
        clearIcon={undefined}
        theme={theme}
      />

      <Text style={styles.titleText}> Codegruppe:</Text>
      <TagsView
        all={props.codegruppen}
        isExclusive={false}
        onSelectionChange={(tag) => {
          props.addOrRemove(tag, "codegruppe");
        }}
        selectedTags={props.selectedTags}
      />
      {props.codegruppen && props.codegruppen.length > 0 ? (
        <TouchableOpacity
          onPress={() => {
            props.resetFilter();
          }}
        >
          <MaterialCommunityIcons
            name="filter-remove-outline"
            size={24}
            color="black"
          />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    margin: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "black",
  },
  postContainer: {
    borderRadius: 5,
    padding: 5,
  },
  titleText: {
    fontSize: 15,
    fontWeight: "bold",
  },
});

export default memo(FlatlistHeader);
