import React, { useEffect, useRef, useState, memo } from "react";
import {
  Image,
  Text,
  Button,
  StyleSheet,
  TouchableHighlight,
  View,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { RootTabScreenProps } from "../types";
import { ICodelist } from "./searchableList";

interface CardItemProps {
  item: ICodelist;
  navigation: RootTabScreenProps<"TabOne">;
}

const CardItem = (props: CardItemProps) => {
  return (
    <TouchableOpacity
      key={props.item.key}
      onPress={() => {
        props.navigation.navigate("Details", {
          listItem: props.item,
        });
      }}
    >
      <Text
        style={{
          fontSize: 18,
          padding: 1,
        }}
      >{`${props.item.code} - ${props.item.beschreibung}`}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    margin: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "black",
  },
  postContainer: {
    borderRadius: 5,
    padding: 5,
  },
});

export default memo(CardItem);
