export default {
  container: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  textInput: {
    width: "100%",
    color: "#495057",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
  },
};
