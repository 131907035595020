import React, { useEffect, useState } from "react";
import {
  Keyboard,
  Platform,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ScrollView,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  Text,
  TextInput,
  Button,
  Avatar,
  DataTable,
  Divider,
  useTheme,
  Snackbar,
} from "react-native-paper";
import SelectDropdown from "react-native-select-dropdown";
import * as FileSystem from "expo-file-system";
import styles from "../constants/Style";

import alert from "../hooks/alert";

export default function TabTwoScreen() {
  const [serverInputValue, setServerInputValue] = useState("");

  const [availableCodelists, setavailableCodelists] = useState([]);
  const [offlineCodelists, setofflineCodelists] = useState([]);
  const [selectedCodelist, setselectedCodelist] = useState({});
  const [visibleSnackbar, setvisibleSnackbar] = useState(false);

  const fileDir = FileSystem.documentDirectory + "codeListenData/";
  const theme = useTheme();

  let snackbarValue = "";
  const onDismissSnackBar = () => setvisibleSnackbar(false);

  const ensureDirExists = async () => {
    const dirInfo = await FileSystem.getInfoAsync(fileDir);
    if (!dirInfo.exists) {
      console.log("directory doesn't exist, creating...");
      await FileSystem.makeDirectoryAsync(fileDir, { intermediates: true });
    }
  };

  const downloadFile = async (gifId: string = "s2", fileName: string) => {
    // const fileDir = FileSystem.documentDirectory + 'giphy/'; //cacheDirectory

    const jsonFileUri = (gifId) => fileDir + gifId + ".json";
    await ensureDirExists();

    const fileUri = jsonFileUri(gifId);

    console.log("Isn't cached locally. Downloading...");
    try {
      await FileSystem.downloadAsync(
        serverInputValue + "/" + fileName,
        fileUri
      );
    } catch {
      snackbarValue =
        "Herunterladen der Codeliste " + element.name + " ist fehlgeschlagen.";
      setvisibleSnackbar(true);
      return "Error";
    }

    return fileUri;
  };
  const deleteJsonFile = async (id: string) => {
    const jsonFileUri = (gifId) => fileDir + gifId + ".json";
    console.log("Deleting Json-File " + id);
    try {
      await FileSystem.deleteAsync(jsonFileUri(id));
    } catch {
      console.log("Error");
      return "Error";
    }
  };

  const toggleOfflineCodelist = (element: JSON) => {
    if (offlineCodelists.includes(element.name)) {
      //delete Codelist
      deleteJsonFile(element.key).then(() => {
        setofflineCodelists(
          offlineCodelists.filter((c) => {
            return c != element.name;
          })
        );
      });
    } else {
      if (Platform.OS != "web") {
        downloadFile(element.key, element.filename).then((uri) => {
          console.log("Download succesful", uri);
          snackbarValue =
            "Herunterladen der Codeliste " + element.name + " erfolgreich.";
          setvisibleSnackbar(true);
          setofflineCodelists([element.name, ...offlineCodelists]);
        });
      }
      //download Codelist
    }
  };
  const checkAndSaveServer = () => {
    if (serverInputValue && serverInputValue != "") {
      setServerInputValue(serverInputValue);
      //Server gültig?
      fetch(serverInputValue + "/codelisten.json") //
        .then((response) => {
          console.log(response);
          if (response.ok) {
            return response.json();
          }
          throw new Error(response.statusText);
        })
        .then((responseJson) => {
          //Speichern der Server URL
          AsyncStorage.setItem("serverUrl", serverInputValue);
          AsyncStorage.setItem(
            "availableCodelists",
            JSON.stringify(responseJson)
          );
          //Speichern
          setavailableCodelists(responseJson);
        })
        .catch((error) => {
          console.log(error);
          //Server nicht gültig.
          //TODO: Meldung ungültige Serveradresse
          alert("Achtung", "Die Angegebene Serveradresse ist ungültig.", [
            { text: "Ok", onPress: () => {} },
            { text: "Abbrechen", onPress: () => {} },
          ]);
        });
    }
  };
  useEffect(() => {
    AsyncStorage.getItem("serverUrl").then((value) => {
      if (value) {
        setServerInputValue(value);
      } else setServerInputValue("");
    });

    AsyncStorage.getItem("availableCodelists").then((value) => {
      if (value) {
        setavailableCodelists(JSON.parse(value));
      } else setavailableCodelists([]);
    });

    AsyncStorage.getItem("offlineCodelists").then((value) => {
      if (value) {
        setofflineCodelists(JSON.parse(value));
      } else setofflineCodelists([]);
    });

    AsyncStorage.getItem("selectedCodelist").then((value) => {
      if (value) {
        setselectedCodelist(JSON.parse(value));
      } else setselectedCodelist({});
    });
  }, []);

  useEffect(() => {
    console.log("Available" + availableCodelists);
  }, [availableCodelists]);
  useEffect(() => {
    AsyncStorage.setItem("offlineCodelists", JSON.stringify(offlineCodelists));
  }, [offlineCodelists]);

  useEffect(() => {
    AsyncStorage.setItem("selectedCodelist", JSON.stringify(selectedCodelist));
  }, [selectedCodelist]);

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={{ flexGrow: 1, gap: 20 }}
      keyboardShouldPersistTaps="handled"
    >
      <Text variant="titleMedium">Codelisten-Server:</Text>
      <TextInput
        value={serverInputValue}
        onChangeText={(serverHost) => setServerInputValue(serverHost)}
        mode="outlined"
      />
      <Button onPress={checkAndSaveServer} icon="content-save" mode="contained">
        Server speichern
      </Button>
      <Divider />

      <Text variant="titleMedium">Aktuell angezeigte Codeliste:</Text>
      <SelectDropdown
        defaultValue={selectedCodelist}
        data={
          Platform.OS == "web"
            ? availableCodelists
            : availableCodelists.filter((c) =>
                offlineCodelists.includes(c.name)
              )
        }
        onSelect={(selectedItem, index) => {
          setselectedCodelist(selectedItem);
        }}
        rowTextForSelection={(item, index) => {
          return item.name;
        }}
        buttonTextAfterSelection={(selectedItem, index) => {
          return selectedItem.name;
        }}
      />
      <Divider />
      {Platform.OS != "web" ? (
        <View>
          <Text variant="titleMedium">Verfügbare Codelisten</Text>
          <DataTable>
            <DataTable.Header>
              <DataTable.Title>Name</DataTable.Title>
              <DataTable.Title>Status</DataTable.Title>
            </DataTable.Header>
            {availableCodelists.map((element, key) => {
              const offline = offlineCodelists.includes(element.name);
              return (
                <DataTable.Row
                  key={element.key}
                  onPress={() => toggleOfflineCodelist(element)}
                >
                  <DataTable.Cell>{element.name}</DataTable.Cell>
                  <DataTable.Cell>
                    {offline ? (
                      <Avatar.Icon size={24} icon="delete" />
                    ) : (
                      <Avatar.Icon size={24} icon="file-download" />
                    )}
                  </DataTable.Cell>
                </DataTable.Row>
              );
            })}
          </DataTable>
        </View>
      ) : null}
      <Snackbar
        visible={visibleSnackbar}
        onDismiss={onDismissSnackBar}
        action={{
          label: "Ok",
          onPress: () => {
            onDismissSnackBar;
          },
        }}
      >
        {snackbarValue}
      </Snackbar>
    </ScrollView>
  );
}
