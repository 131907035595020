import { StatusBar } from "expo-status-bar";
import { useEffect, useState } from "react";
import {
  Button,
  Dimensions,
  Modal,
  Platform,
  StyleSheet,
  TouchableOpacity,
  Image,
} from "react-native";

import ImageViewer from "react-native-image-zoom-viewer";

import EditScreenInfo from "../components/EditScreenInfo";
import { ICodelist } from "../components/searchableList";
import TabBarIcon from "../components/TabBarIcon";
import { Text, View } from "../components/Themed";

export default function DetailScreen(routingObject) {
  const [modalVisible, setmodalVisible] = useState<boolean>(false);
  const [item, setitem] = useState<ICodelist>(null);
  var bemerkung = [];
  var images = [];
  const toggleModal = () => {
    setmodalVisible(!modalVisible);
  };

  useEffect(() => {
    const tempItem = routingObject.route.params.listItem;
    if (tempItem.bilder) {
      for (var i = 0; i < tempItem.bilder.length; i++) {
        var element = {
          url:
            "data:image/jpg;base64," +
            JSON.stringify(tempItem.bilder[i]).replaceAll('"', ""),
          width: Dimensions.get("window").width,
          height: Dimensions.get("window").height,
          props: {
            resizeMode: "contain",
          },
        };
        images.push(element);
      }
    }
    routingObject.navigation.setOptions({
      title: JSON.stringify(tempItem.beschreibung).replaceAll('"', ""),
    });
    setitem(tempItem);
  }, []);

  const tempItem = routingObject.route.params.listItem;
  if (tempItem.bilder) {
    for (var i = 0; i < tempItem.bilder.length; i++) {
      var element = {
        url:
          "data:image/jpg;base64," +
          JSON.stringify(tempItem.bilder[i]).replaceAll('"', ""),
        width: Dimensions.get("window").width,
        height: Dimensions.get("window").height,
        props: {
          resizeMode: "contain",
        },
      };
      images.push(element);
    }
  }
  return (
    <View style={styles.container}>
      {item && (
        <View>
          <Text>Code: {`${item.code}`}</Text>
          <Text>Gruppierung: {`${item.bahnart}, ${item.codegruppe}`}</Text>
          <Text>{`Bemerkung: ${JSON.stringify(item.bemerkung)
            .replaceAll('"', "")
            .split("\\n")
            .join("\n")}`}</Text>
          {item.bilder ? (
            item.bilder.length > 0 ? (
              <Button
                title={`${item.bilder.length} Bilde(r)`}
                onPress={() => toggleModal()}
              />
            ) : (
              <Text> {""} </Text>
            )
          ) : (
            <Text> {""} </Text>
          )}
          <Modal
            visible={modalVisible}
            transparent={true}
            onRequestClose={() => {
              toggleModal();
            }}
          >
            <View style={{ flex: 1 }}>
              <ImageViewer
                imageUrls={images}
                enableSwipeDown={true}
                onSwipeDown={() => {
                  toggleModal();
                }}
              />
              <View
                style={{
                  position: "absolute",
                  top: 20,
                  left: 20,
                  width: 24,
                  height: 24,
                }}
              >
                <TouchableOpacity onPress={() => toggleModal()}>
                  <TabBarIcon name="close" color={"white"} />
                </TouchableOpacity>
              </View>
            </View>
          </Modal>

          <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
